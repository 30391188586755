// HomeBannerItem.js
'use strict';
import { connect } from 'react-redux';

import HomeBannerItem from '../component/HomeBannerItem.jsx';

import fetchBanner from '../action/fetchBanner.js';
import getBanner from '../selector/getBanner.js';
import getMeData from '../selector/getMeData.js';

const mapStateToProps = (state, { bannerLink }) => {
  return {
    bannerData: getBanner(state, bannerLink),
    meId: getMeData(state, 'id'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchBanner: ({ category, url }) =>
      dispatch(fetchBanner({ category, url })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeBannerItem);
