// Swiper.jsx
import React, { forwardRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

export const Swiper = forwardRef(
  ({ modules = [], children, ...otherProps }, ref) => {
    const [isSwiperLoaded, setIsSwiperLoaded] = useState(
      Swiper.Component !== undefined
    );

    useEffect(() => {
      const loadSwiper = async () => {
        const [{ default: SwiperWithStyleComponents }, Modules] =
          await Promise.all([
            import('../style/SwiperWithDefaultStyle.js'),
            import('swiper/modules'),
          ]);
        Swiper.Component = SwiperWithStyleComponents;
        Swiper.Modules = Modules;
        setIsSwiperLoaded(true);
      };

      if (!Swiper.Component && !Swiper.Modules) loadSwiper();

      return () => {
        setIsSwiperLoaded(false);
      };
    }, []);

    if (isSwiperLoaded) {
      const Component = Swiper.Component;

      return (
        <Component
          ref={ref}
          modules={modules.map(module => Swiper.Modules?.[module])}
          {...otherProps}
        >
          {children}
        </Component>
      );
    }

    return (
      <StyledSwiper ref={ref} {...otherProps}>
        <div className="swiper-wrapper">{children}</div>
      </StyledSwiper>
    );
  }
);

Swiper.displayName = 'SwagSwiper';
Swiper.Component = undefined;
Swiper.Modules = undefined;

Swiper.propTypes = {
  children: PropTypes.node.isRequired,
  modules: PropTypes.array,
};

export const SwiperSlide = forwardRef(({ children, ...otherProps }, ref) => {
  const [isSwiperSlideLoaded, setIsSwiperSlideLoaded] = useState(
    SwiperSlide.Component !== undefined
  );

  useEffect(() => {
    const loadSwiperSlide = async () => {
      const { SwiperSlide: OriginalSwiperSlide } = await import('swiper/react');
      SwiperSlide.Component = OriginalSwiperSlide;
      setIsSwiperSlideLoaded(true);
    };
    if (!SwiperSlide.Component) loadSwiperSlide();

    return () => {
      setIsSwiperSlideLoaded(false);
    };
  }, []);

  if (isSwiperSlideLoaded) {
    const Component = SwiperSlide.Component;
    return (
      <Component ref={ref} {...otherProps}>
        {children}
      </Component>
    );
  }

  return null;
});

SwiperSlide.displayName = 'SwagSwiperSlide';
SwiperSlide.Component = undefined;

SwiperSlide.propTypes = {
  children: PropTypes.node,
};

const StyledSwiper = styled.div`
  display: flex;
  overflow: hidden;
  gap: 12px;

  > * {
    flex-shrink: 0;
    position: relative;
  }
`;

export const SwiperAutoHeightStyle = css`
  // Integrate with css below in swiper-bundle.css
  /* Auto Height */
  /*   
  .swiper-autoheight,
  .swiper-autoheight .swiper-slide {
    height: auto;
  }
  .swiper-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }
  .swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    backface-visibility: hidden;
  }
 */

  height: auto;
  .swiper-wrapper {
    height: auto;
    align-items: flex-start;
  }
  .swiper-slide {
    width: 100%;
    height: auto;
  }
`;

export const SwiperPaginationDefaultStyle = css`
  position: relative;

  .swiper-slide {
    overflow: hidden;
  }

  .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    &.swiper-pagination-hidden {
      opacity: 0;
    }
  }

  .swiper-pagination-clickable {
    .swiper-pagination-bullet {
      cursor: pointer;
    }
  }

  .swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0;
  }

  .swiper-pagination-bullets {
    bottom: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    .swiper-pagination-bullet {
      border: none;
      margin: 0;
      padding: 0;
      box-shadow: none;
      appearance: none;

      width: 6px;
      height: 6px;
      opacity: 0.3;
      display: inline-block;
      border-radius: 50%;
      background: #ffffff;

      margin: 0 4px;
      transition:
        200ms transform,
        200ms left;
    }
    .swiper-pagination-bullet {
      position: relative;
    }
    .swiper-pagination-bullet-active {
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      transform: scale(1);
    }
    .swiper-pagination-bullet-active-main {
      transform: scale(1);
    }
    .swiper-pagination-bullet-active-prev {
      transform: scale(0.66);
    }
    .swiper-pagination-bullet-active-prev-prev {
      transform: scale(0.33);
    }
    .swiper-pagination-bullet-active-next {
      transform: scale(0.66);
    }
    .swiper-pagination-bullet-active-next-next {
      transform: scale(0.33);
    }
    .swiper-pagination-bullet:only-child {
      display: none !important;
    }
  }

  .swiper-pagination-fraction {
    /*  */
  }

  .swiper-container-rtl {
    .swiper-pagination-bullet {
      transition:
        200ms transform,
        200ms right;
    }
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
`;

export default Swiper;
