// HomeBanner.jsx
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, breakpoint } from '../style/variables.js';
import media from '../style/media.js';

import { Swiper, SwiperSlide } from '../component/Swiper.jsx';
import BannerPlaceholderResource from '../../img/placeholder-banner.svg';
import RightArrowResource from '../../img/ic-arrow-right-white.svg';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import {
  BANNER_HEIGHT,
  BANNER_WIDTH,
  SLIDES_PER_VIEW,
} from '../resource/bannerConstants.js';
import HomeBannerItem from '../container/HomeBannerItem.js';
import WithIntersectionObserver from './WithIntersectionObserver.jsx';

const BREAK_POINT = breakpoint.tablet;

export class HomeBanner extends React.PureComponent {
  state = { isClient: false };
  nextTick = null;
  swiperRef = createRef(null);
  renderPlaceholderSlide = () => {
    return (
      <StatefulImageWrapper>
        <PlaceholderWrapper data-key="target">
          <img
            src={BannerPlaceholderResource}
            alt="banner placeholder"
            width={300}
            height={136}
          />
        </PlaceholderWrapper>
      </StatefulImageWrapper>
    );
  };
  renderSlide = (bannerUrl, index) => {
    const { category } = this.props;

    return (
      <SwiperSlide key={index}>
        <WithIntersectionObserver>
          {({ isIntersecting }) => {
            return (
              <div>
                <HomeBannerItem
                  category={category}
                  bannerLink={bannerUrl}
                  isIntersecting={isIntersecting}
                  index={index}
                />
              </div>
            );
          }}
        </WithIntersectionObserver>
      </SwiperSlide>
    );
  };
  renderPlaceholderSwiper = () => {
    return (
      <PlaceholderSwiperWrapper>
        {Array(3)
          .fill(1)
          .map((_, index) => {
            return (
              <PlaceholderBannerWrapper key={index}>
                {this.renderPlaceholderSlide()}
              </PlaceholderBannerWrapper>
            );
          })}
      </PlaceholderSwiperWrapper>
    );
  };
  componentDidMount() {
    this.nextTick = setTimeout(() => {
      this.setState({ isClient: true });
    }, 0);
  }
  componentWillUnmount() {
    clearTimeout(this.nextTick);
  }
  render() {
    const { bannerIds } = this.props;
    const { isClient } = this.state;
    const hasBanners = bannerIds.length > 0;

    if (!hasBanners) {
      return null;
    }

    if (!isClient) {
      return (
        <StyledHomeBanner>
          {this.renderPlaceholderSwiper()}
          <PrevButton></PrevButton>
          <NextButton></NextButton>
        </StyledHomeBanner>
      );
    }

    // if the bannerIds.length is less than SLIDES_PER_VIEW + SLIDES_PER_VIEW, the swiper won't function properly
    // duplicate the ids to make sure the swiper work properly
    const neededBannerIds =
      bannerIds.length < SLIDES_PER_VIEW + SLIDES_PER_VIEW
        ? [...bannerIds, ...bannerIds, ...bannerIds]
        : bannerIds;

    return (
      <StyledHomeBanner>
        <SwiperWrapper
          slidesPerView={'auto'}
          centeredSlides={true}
          breakpoints={{
            [BREAK_POINT]: {
              slidesPerView: SLIDES_PER_VIEW,
              slidesPerGroup: SLIDES_PER_VIEW,
              centeredSlides: false,
            },
          }}
          spaceBetween={12}
          loop={true}
          loopedSlides={SLIDES_PER_VIEW}
          onBeforeInit={swiper => {
            this.swiperRef.current = swiper;
          }}
        >
          {neededBannerIds.map(this.renderSlide)}
        </SwiperWrapper>
        <PrevButton
          onClick={() => this.swiperRef.current?.slidePrev()}
        ></PrevButton>
        <NextButton
          onClick={() => this.swiperRef.current?.slideNext()}
        ></NextButton>
      </StyledHomeBanner>
    );
  }
}

HomeBanner.propTypes = {
  category: PropTypes.string,
  bannerIds: PropTypes.array,
};

HomeBanner.defaultProps = {
  category: '',
  bannerIds: [],
};

const StyledHomeBanner = styled.article`
  position: relative;
  max-width: 1008px;
  margin-right: auto;
  margin-left: auto;
  min-height: 136px;
`;

const PlaceholderSwiperWrapper = styled.div`
  position: absolute;
  display: flex;
  left: 50%;
  transform: translate(-50%, 0);
`;

const SwiperWrapper = styled(Swiper)`
  max-width: 924px;
  .swiper-slide {
    width: ${BANNER_WIDTH}px;
    height: ${BANNER_HEIGHT}px;
  }
`;

const NextButton = styled.button`
  ${ResetButtonStyle}
  display: block;
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
  position: absolute;
  z-index: 2;
  top: 50%;
  background-color: rgba(100, 100, 100, 0.8);
  border-radius: 50%;
  margin-top: -15px;
  background-image: url(${RightArrowResource});
  right: 0;
  ${media.tablet`
    visibility: hidden;
  `};
  ${media.mobile`
    visibility: hidden;
  `};
`;

const PrevButton = styled(NextButton)`
  transform: rotate(180deg);
  left: 0;
`;

const PlaceholderBannerWrapper = styled.div`
  position: relative;
  width: 300px;
  height: 136px;
  overflow: hidden;
  & + & {
    margin-left: 12px;
  }
`;

const PlaceholderWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: solid 1px ${color.grey[700]};
`;

const StatefulImageWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;

export default HomeBanner;
