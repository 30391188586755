// fetchOembedData.js
'use strict';
import { withScope, captureException } from '@sentry/browser';
import fetch from '../resource/customFetch.js';
import getMeData from '../selector/getMeData.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import { localeConvertor } from '../resource/i18n.js';
import {
  MERGE_OPERATION_DATA,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

export const selectPathPrefix = ['oembed'];

/**
 * Fetch oembed data
 * @kind action
 * @param {string} {url} - the url to retrieve embedding information for.
 * @param {string} {language} - embedding data content language.
 * @param {string} {maximumWidth} - embedding data content maximum width.
 * @param {string} {maximumHeight} - embedding data content maximum height.
 * @return {Promise} Action promise.
 */
const fetchOembedData =
  ({ url, language, maximumWidth, maximumHeight }) =>
  async (dispatch, getState) => {
    const token = getMeData(getState(), 'token');
    const selectPath = [...selectPathPrefix, url];
    const fetchOptions = {
      method: 'GET',
      headers: {
        ...getHeaders(),
      },
    };
    const apiURL = getResourceUrl({ endpoint: '/oembed' });

    if (token) {
      fetchOptions.headers.Authorization = `Bearer ${token}`;
    }

    apiURL.searchParams.set('url', url);

    if (language) {
      apiURL.searchParams.set(
        'lang',
        localeConvertor({
          locale: language,
          isISO639: true,
        })
      );
    }
    if (maximumWidth) {
      apiURL.searchParams.set('maxwidth', maximumWidth);
    }
    if (maximumHeight) {
      apiURL.searchParams.set('maxheight', maximumHeight);
    }

    dispatch({
      type: SET_NETWORKING_FETCHING,
      payload: { selectPath },
    });

    let response = null;
    try {
      response = await fetch(apiURL.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: apiURL,
        });
      }

      const oembedData = await response.json();

      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath,
          data: oembedData,
        },
      });
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      if (response?.status === 500) {
        withScope(scope => {
          scope.setExtra('requestHref', apiURL.href);
          captureException(new Error('fetchOembedData() 500 error'));
        });
      }
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchOembedData;
