// HomeBanner.js
import { connect } from 'react-redux';

import HomeBanner from '../component/HomeBanner.jsx';
import getBannerIds from '../selector/getBannerIds.js';

const mapStateToProps = (state, { category }) => {
  const bannerIds = getBannerIds(state, category);
  return {
    bannerIds,
  };
};

export default connect(mapStateToProps, null)(HomeBanner);
