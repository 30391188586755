// getBannerIds.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';
import getRemoteConfigData from './getRemoteConfigData.js';
import {
  HOME_BANNERS,
  HOME_BANNER_PADDINGS,
  VIDEO_BANNERS,
  VIDEO_BANNER_PADDINGS,
} from '../RemoteConfigKeys.js';
import { SLIDES_PER_VIEW } from '../resource/bannerConstants.js';

/**
 * Get banner url list
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} category - banner categotry.
 * @return {array} array of each banner url.
 */
const getBannerIds = createCachedSelector(
  (state, category) => category,
  state => getRemoteConfigData(state, HOME_BANNERS),
  state => getRemoteConfigData(state, VIDEO_BANNERS),
  state => getRemoteConfigData(state, HOME_BANNER_PADDINGS),
  state => getRemoteConfigData(state, VIDEO_BANNER_PADDINGS),
  (
    category,
    homeBanners,
    videoBanners,
    defaultHomeBanners,
    defaultVideoBanners
  ) => {
    const isHomeBanner = 'pseudo_banner' === category;
    const isVideoBanner = 'pseudo_video_banner' === category;
    const banners = isHomeBanner
      ? homeBanners
      : isVideoBanner
        ? videoBanners
        : undefined;

    const defaultBanners = isHomeBanner
      ? defaultHomeBanners
      : isVideoBanner
        ? defaultVideoBanners
        : undefined;

    if (banners.length % SLIDES_PER_VIEW === 0) {
      return banners;
    }

    const bannersWithDefaultBanners = [...banners, ...defaultBanners];
    const requiredPlaceholderCount =
      bannersWithDefaultBanners.length % SLIDES_PER_VIEW;
    if (requiredPlaceholderCount === 0) {
      return bannersWithDefaultBanners;
    }

    const placeholderCount = SLIDES_PER_VIEW - requiredPlaceholderCount;
    // add '' to display placeholder
    const placeholderBanners = [...Array(placeholderCount)].map(() => '');
    return [...bannersWithDefaultBanners, ...placeholderBanners];
  }
)((state, category) => `${category}`);

export default getBannerIds;
