// getIsResourceExisted.js
'use strict';
import fetch from '../resource/customFetch.js';
import env from '../resource/env.js';
/**
 * Get is resource existed
 * @param {string} url - resource url.
 * @returns {boolean} return true when resource is existed.
 */
const getIsResourceExisted = async ({ url }) => {
  try {
    const isDecryptionRequired = env.FEATURE_RESOURCE_DECRYPTION === '1';
    const targetUrl = isDecryptionRequired
      ? url?.replace(env.PUBLIC_URL_PREFIX, env.PUBLIC_ENCRYPTED_URL_PREFIX)
      : url;
    const response = await fetch(targetUrl, { method: 'GET' });

    if (response.ok) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

export default getIsResourceExisted;
