// WithIntersectionObserver.jsx
import React from 'react';
import PropTypes from 'prop-types';
import Observer from '@researchgate/react-intersection-observer';

/**
 * check parameters at https://github.com/researchgate/react-intersection-observer to see more details
 *
 * WithIntersectionObserver will use element's reference, need to pass `forwardRef: true` as option in react-redux connect function
 */
export class WithIntersectionObserver extends React.PureComponent {
  state = {
    isIntersected: false,
    isIntersecting: false,
    intersectionRatio: 0,
  };
  handleIntersection = ({ isIntersecting, intersectionRatio }, unobserve) => {
    const { isIntersected: prevIsIntersected } = this.state;
    const { onChange, shouldKeepObserve } = this.props;
    const isIntersected = prevIsIntersected || isIntersecting;
    onChange({ isIntersected, isIntersecting, intersectionRatio }, unobserve);
    this.setState({ isIntersected, isIntersecting, intersectionRatio });

    if (!shouldKeepObserve && isIntersecting) {
      unobserve();
    }
  };
  render() {
    const { root, rootMargin, threshold, disabled } = this.props;
    const { isIntersected, isIntersecting, intersectionRatio } = this.state;
    return (
      <Observer
        onChange={this.handleIntersection}
        root={root}
        rootMargin={rootMargin}
        threshold={threshold}
        disabled={disabled}
      >
        {this.props.children({
          isIntersected,
          isIntersecting,
          intersectionRatio,
        })}
      </Observer>
    );
  }
}

WithIntersectionObserver.propTypes = {
  children: PropTypes.func.isRequired,
  root: PropTypes.any,
  rootMargin: PropTypes.string,
  threshold: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  /**
   * @param {boolean} shouldKeepObserve - does need to remove observer when isIntersecting change to true.
   */
  shouldKeepObserve: PropTypes.bool,
};

WithIntersectionObserver.defaultProps = {
  onChange: () => null,
  shouldKeepObserve: false,
};

export default WithIntersectionObserver;
