// fetchBanner.js
'use strict';
import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';
import getOperationData from '../selector/getOperationData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import fetchOembedData from '../action/fetchOembedData.js';
import getIsResourceExisted from '../resource/getIsResourceExisted.js';

/**
 * Fetch banner metadata from banner url
 * @kind action
 * @param {string} {category} - banner category.
 * @param {string} {url} - banner url.
 * @return {Promise} Action promise.
 */
const fetchBanner =
  ({ category, url }) =>
  async (dispatch, getState) => {
    const selectPath = ['banners', category, url];

    const isAlreadyFetched = getNetworkingData(
      getState(),
      ['oembed', url],
      'isFetched'
    );
    const isFetching = getNetworkingData(
      getState(),
      ['oembed', url],
      'isFetching'
    );

    if (isAlreadyFetched || isFetching) {
      return { type: '' };
    }

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
    try {
      await dispatch(fetchOembedData({ url }));
      const oembedData = getOperationData(getState(), ['oembed'], url);
      const isExisted = getIsResourceExisted({
        url: oembedData['url'],
      });
      if (!oembedData || !isExisted) {
        return { type: '' };
      }

      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchBanner;
