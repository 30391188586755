// getBanner.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';
import getMeData from './getMeData.js';
import getOperationData from './getOperationData.js';
import { getIsInternalLink } from '../resource/getInternalLink.js';

const getIsSWAGSite = url => {
  return url.search(/swag\.live/) !== -1;
};

/**
 * get refined banner metadata
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} url - banner url.
 * @return {object} refined banner metadata.
 */
const getBanner = createCachedSelector(
  (state, url) => url,
  (state, url) => getOperationData(state, ['oembed'], url),
  state => getMeData(state, 'token'),
  (url = '', banner = {}, token) => {
    const refineBannerPayload = id => {
      let url = null;
      try {
        url = new URL(id);
      } catch (error) {
        if (
          typeof id !== 'string' ||
          !Object.keys(banner).length ||
          !id.startsWith('/')
        )
          return null;

        return {
          src: banner.thumbnail_url,
          link: id,
          reactLink: true,
        };
      }
      const isInternalLink = getIsInternalLink({
        link: id,
        whitelist: ['app.swag.live', 'swag.live'],
      });
      const isSWAGSite = getIsSWAGSite(id);
      const isEventSite = isSWAGSite && !isInternalLink;

      url.searchParams.delete('si');

      if (token && isEventSite) {
        url.searchParams.set('auth', token);
      }

      return {
        src: banner.thumbnail_url,
        link: isEventSite ? url.href : `${url.pathname}${url.search}`,
        reactLink: isInternalLink,
      };
    };

    return refineBannerPayload(url);
  }
)((state, url) => `${url}`);

export default getBanner;
